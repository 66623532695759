import React, { FC, useContext } from "react";
import clsx from "clsx";
// @ts-ignore
import { PageHeader } from "@ruter-ds/rds-components";
import { AppContext } from "../containers/App";

import "./header.scss";

const Header: FC = () => {
  const {
    appState: {
      owner: { name = "" },
    },
  } = useContext(AppContext);

  return (
    <PageHeader className={clsx("header", name && `header--${name}`)}>
      {name && (
        <img
          className="header__logo"
          src={`/logo/${name}.svg`}
          alt={`logoen til ${name}`}
        />
      )}
    </PageHeader>
  );
};

export default Header;

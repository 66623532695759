import React, { FC, useState, createContext } from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import Content from "../../Content";
import { Owner, initialOwner } from "../../../types/Owner";
import { OS } from "../../../types/OS";
import { RedirectUrlCreator } from "../../../constants/redirectUrlList";

import "./app.scss";
import { getOS } from "./util";
import { AppScheme, initialAppScheme } from "../../../types/AppScheme";

export interface AppState {
  owner: Owner;
  appScheme: AppScheme;
  pickupCode: string;
  os: OS;
  redirectUrls: RedirectUrlCreator[];
}

const initialAppState: AppState = {
  owner: initialOwner,
  appScheme: initialAppScheme,
  pickupCode: "",
  os: OS.OTHER,
  redirectUrls: [],
};

interface appContext {
  appState: AppState;
  functions: {
    setAppState: (appState: AppState) => void;
  };
}

const initialAppContext: appContext = {
  appState: initialAppState,
  functions: {
    setAppState: () => {},
  },
};

export const AppContext = createContext(initialAppContext);

const App: FC = ({ children }) => {
  const [appState, setAppState] = useState(() => ({
    ...initialAppState,
    os: getOS(),
  }));

  return (
    <div className="container">
      <AppContext.Provider value={{ appState, functions: { setAppState } }}>
        <Header />
        <Content>{children}</Content>
        <Footer />
      </AppContext.Provider>
    </div>
  );
};

export default App;

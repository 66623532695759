import React, { FC } from "react";
import { Switch, Route, Redirect as RedirectRoute } from "react-router-dom";
import App from "../containers/App";
import NotFound from "../containers/NotFound";
import Redirect from "../containers/Redirect";
import Landing from "../containers/Landing";

const Router: FC = () => {
  return (
    <App>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route
          exact
          path="/H/:ownerId/:pickupCode"
          component={Redirect}
        />
        <Route exact path="/404" component={NotFound} />
        <RedirectRoute to="/404" />
      </Switch>
    </App>
  );
};

export default Router;

import React, { FC, useEffect, useContext } from "react";
import axios, { AxiosError } from "axios";
import Article from "../../Article";
import { AppContext } from "../App";
import { OwnerId, Owner } from "../../../types/Owner";
import { useParams, useHistory } from "react-router-dom";
import { getOS } from "../App/util";
import {
  redirectUrlList,
  RedirectUrlCreator,
} from "../../../constants/redirectUrlList";
import { AppState } from "../App/App";
import { AppScheme } from "../../../types/AppScheme";
import ButtonContainer from "../../Buttons/ButtonContainer";

const useGetOwnerAndAppScheme = (setAppState: (appstate: AppState) => void) => {
  const {
    ownerId = "",
    pickupCode = "",
  } = useParams<{
    ownerId: OwnerId;
    pickupCode: string;
  }>();

  const history = useHistory();

  useEffect(() => {
    const os = getOS();
    const redirectUrls = redirectUrlList[os];

    const ownerPromise = axios.get<{ owner: Owner }>(`/api/owner/${ownerId}`);
    const appSchemePromise = axios.get<{ appScheme: AppScheme }>(
      `/api/appscheme/${ownerId}`
    );
    // console.log(ownerId, pickupCode);
    Promise.all([ownerPromise, appSchemePromise])
      .then(([ownerResponse, appSchemeResponse]) => {
        const { owner } = ownerResponse.data;
        const { appScheme } = appSchemeResponse.data;
        setAppState({
          owner,
          appScheme,
          pickupCode,
          os,
          redirectUrls,
        });
      })
      .catch((e: AxiosError) => {
        const newRoute = `/${e.response?.status ?? "404"}`;
        history.push(newRoute);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useChangeTitle = (displayName: string) => {
  useEffect(() => {
    document.title = displayName;
  }, [displayName]);
};

const useRedirect = (
  redirectUrls: RedirectUrlCreator[],
  appScheme: AppScheme,  
  id: OwnerId,
  pickupCode: string
) => {
  useEffect(() => {
    if (appScheme && id && pickupCode) {
      redirectUrls.forEach((urlCreator, index) => {
        const url = urlCreator(appScheme, id, pickupCode);
        setTimeout(function () {
            window.location.assign(url);
          }, 1000 * index); //will call the function after 1 secs.
      });
    }
  }, [appScheme, id, pickupCode, redirectUrls]);
};

const Redirect: FC = () => {
  const {
    appState: {
      os,
      redirectUrls,
      pickupCode,
      appScheme,
      owner: {
        id,
        displayName,
        app: { appName, url },
      },
    },
    functions: { setAppState },
  } = useContext(AppContext);
  useGetOwnerAndAppScheme(setAppState);
  useChangeTitle(displayName);
  useRedirect(redirectUrls, appScheme, id, pickupCode);
  return (
    <>
      <Article
        header={`Last ned siste versjon av ${appName}`}
        content={`For å kunne hente billetten må du laste ned siste versjon av ${appName}.`}
      />
      <ButtonContainer os={os} url={url} />
    </>
  );
};

export default Redirect;

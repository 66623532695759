import React, { FC } from "react";

import "./content.scss";

const Content: FC = ({ children }) => {
  return (
    <main role="main" className="content">
      <div className="content__center">{children}</div>
    </main>
  );
};

export default Content;

import React, { FC, ReactNode } from "react";

import "./article.scss";

interface Props {
  header?: ReactNode;
  introduction?: ReactNode;
  content?: ReactNode;
}

const Article: FC<Props> = ({ header, introduction, content }) => {
  return (
    <article className="article readability page--palm-spacing">
      <h1 className="article__header">{header}</h1>
      <p className="article__introduction">{introduction}</p>
      <p className="article__content">{content}</p>
    </article>
  );
};

export default Article;

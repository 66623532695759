import React, { FC } from "react";
import StoreButton from "../StoreButton";
import { OS } from "../../../types/OS";

import "./button-container.scss";

type Props = {
  os: OS;
  url: { [key in OS]: string };
};

const ButtonContainer: FC<Props> = ({ os, url }) => {
  return (
    <div className="button-container">
      <div className="button-container__content">
        {os === OS.OTHER ? (
          <>
            <StoreButton os={OS.ANDROID} href={url[OS.ANDROID]} />
            <StoreButton os={OS.IOS} href={url[OS.IOS]} />
          </>
        ) : (
          <StoreButton os={os} href={url[os]} />
        )}
      </div>
    </div>
  );
};

export default ButtonContainer;

import { OS } from "./OS";

export type AppScheme = {
  [OS.ANDROID]: { current: string; legacy: string };
  [OS.IOS]: { current: string; legacy: string };
};

export const initialAppScheme: AppScheme = {
  [OS.ANDROID]: {
    current: "",
    legacy: ""
  },
  [OS.IOS]: {
    current: "",
    legacy: ""
  }
};

import { AppScheme } from "../types/AppScheme";
import { OS } from "../types/OS";

export type RedirectUrlCreator = (
  appScheme: AppScheme,
  owner: string,
  pickupCode: string
) => string;

const iosRedirectUrl: RedirectUrlCreator = (
  appScheme,
  owner,
  pickupCode
) =>
  `${appScheme.ios.current}://H/${owner}/${pickupCode}/?hentekode=${pickupCode}`;
const androidRedirectUrl1: RedirectUrlCreator = (
  appScheme,
  owner,
  pickupCode
) =>
  `intent://${appScheme.android.legacy}/V7/RuterMobile/H/${owner}/${pickupCode}/#Intent;scheme=https;end`;
const androidRedirectUrl2: RedirectUrlCreator = (
  appScheme,
  _owner,
  pickupCode
) => `${appScheme.android.current}://pickupcode/${pickupCode}`;

export const redirectUrlList: { [key in OS]: RedirectUrlCreator[] } = {
  [OS.ANDROID]: [androidRedirectUrl2, androidRedirectUrl1],
  [OS.IOS]: [],
  [OS.OTHER]: []
};

import React, { FC } from "react";
import Article from "../../Article";

const NotFound: FC = () => {
  return (
    <Article
      header="Fant ikke side"
      content="Vi finner ikke siden du prøvde å åpne, er du sikker på at linken er riktig?"
    />
  );
};

export default NotFound;

import { OS } from "./OS";

//duplicate enum in backend
export enum OwnerId {
  RUTER = "1",
  KOLOMBUS = "500",
  OKT = "150",
  AKT = "109",
  BRAKAR = "102",
  TROMS = "190",
}

export type Owner = {
  id: OwnerId;
  name: string;
  displayName: string;
  app: {
    appName: string;
    url: { [key in OS]: string };
  };
};

export const initialOwner: Owner = {
  id: OwnerId.RUTER,
  displayName: "Ruter",
  name: "ruter",
  app: {
    appName: "",
    url: {
      [OS.ANDROID]: "",
      [OS.IOS]: "",
      [OS.OTHER]: "",
    },
  },
};

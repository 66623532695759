import { OS } from "../../../types/OS";

export const getOS = () => {
  if (/Android/.test(navigator.userAgent)) {
    return OS.ANDROID;
  }
  if (/Mobi|iPad|iPhone|iPod/i.test(navigator.platform)) {
    return OS.IOS;
  }
  return OS.OTHER;
};

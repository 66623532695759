import React, { FC, useMemo } from "react";
import { OS } from "../../../types/OS";

import "./store-button.scss";

interface Props {
  os: OS;
  href: string;
}

const getLogoName = (os: OS) => {
  switch (os) {
    case OS.ANDROID:
      return "playstore-black-no.svg";
    case OS.IOS:
      return "appstore-black-no.svg";
    case OS.OTHER:
    default:
      return "";
  }
};

const StoreButton: FC<Props> = ({ os, href }) => {
  const logoName = useMemo(() => getLogoName(os), [os]);

  return (
    <a href={href} className="store-button">
      <img
        className="store-button__logo"
        src={`/storelogo/${logoName}`}
        alt={`logo til nettbutikk for ${os}`}
      />
    </a>
  );
};

export default StoreButton;
